import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import { postFileApi, sendUserAnswersApi } from "src/_utils/api/api";
import {
  setHowShallWeContactYouErrorMessageAC,
  setUserNameAC,
} from "src/store/actions";
import styles from "./formViewFooter.module.scss";
import { Link } from "react-router-dom";
import ROUTES from "src/_utils/routes/routes";
import parsePhoneNumberFromString from "libphonenumber-js";
import { validateEmail } from "src/_utils/emailValidator";
import { RotatingLines } from "react-loader-spinner";

const FormViewFooter: React.FC<any> = ({
  isLastPage,
  setIsShowEndingPage,
  currentFormId,
  businessName,
  privacyPolicyLink,
  formColorTheme,
  isHidePoweredLabel,
}: any) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state: any) => state.userInfo);
  const userAnswers = useSelector((state: any) => state.userAnswers);
  const userName = useSelector((state: any) => state.userName);

  const { firstName, lastName, phoneNumber, email } = userInfo;

  const captchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [captchaError, setCaptchaError] = useState(false);
  const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onCaptchaChange = (token: string | null) => {
    setRecaptchaToken(token);
    setCaptchaError(false);
  };

  const keyCount: { [key: string]: number } = {};

  const transformedAnswers = userAnswers.map((item: any) => {
    let key = item?.question;

    if (keyCount[key]) {
      keyCount[key]++;
      key += keyCount[key];
    } else {
      keyCount[key] = 1;
    }

    return { [key]: item?.answer };
  });

  const arrayOfObjects = Object.entries(userInfo).map(([key, value]) => ({
    [key]: value,
  }));

  const combinedArray = transformedAnswers.concat(arrayOfObjects);

  const resultObj = combinedArray.reduce((acc: any, item: any) => {
    const key = Object.keys(item)[0];
    acc[key] = item[key];
    return acc;
  }, {});

  const userAnswersResult = {
    formId: currentFormId,
    username: userName,
    formData: resultObj,
    recaptchaToken,
  };

  const handleSendAnswers = async () => {
    const validatedPhoneNumber = parsePhoneNumberFromString(phoneNumber);

    if (firstName === "" || lastName === "" || email === "") {
      dispatch(
        setHowShallWeContactYouErrorMessageAC(
          "Please fill in all fields marked with *"
        )
      );
      return;
    } else if (!validatedPhoneNumber || !validatedPhoneNumber.isValid()) {
      dispatch(setHowShallWeContactYouErrorMessageAC("Invalid phone number"));
      return;
    } else if (!validateEmail(email)) {
      dispatch(setHowShallWeContactYouErrorMessageAC("Invalid email"));
      return;
    } else {
      setIsLoading(true);
      dispatch(setHowShallWeContactYouErrorMessageAC(""));

      if (!recaptchaToken) {
        setIsLoading(false);
        setCaptchaError(true);
        return;
      }

      const formDataEntries = Object.entries(userAnswersResult.formData);
      const fileUploadPromises = formDataEntries.map(async ([key, value]) => {
        if (value instanceof File) {
          const formData = new FormData();
          formData.append("file", value);

          try {
            const response = await postFileApi(currentFormId, formData);
            const fileUrl = response?.data?.data?.fileUrl;

            if (fileUrl) {
              userAnswersResult.formData[key] = fileUrl;
            }
          } catch (error) {
            console.error(`Failed to upload file for key ${key}`, error);
          }
        }
      });

      await Promise.all(fileUploadPromises);

      try {
        await sendUserAnswersApi(userAnswersResult);
        dispatch(setUserNameAC(""));
        setIsShowEndingPage(true);
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to send user answers", error);
      }
    }
  };

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.wrapper}>
        <div className={styles.buttonsWrap}>
          {isLastPage && (
            <>
              <button
                onClick={handleSendAnswers}
                className={`${styles.navButton} ${
                  !isPrivacyPolicyChecked ? styles.disabled : ""
                }`}
                disabled={!isPrivacyPolicyChecked}
                style={{ backgroundColor: formColorTheme }}
              >
                {!isLoading ? (
                  "Submit"
                ) : (
                  <RotatingLines
                    visible={true}
                    width="30"
                    strokeWidth="5"
                    animationDuration="0.75"
                    strokeColor="white"
                  />
                )}
              </button>
            </>
          )}
        </div>
        {isLastPage && (
          <div>
            <div className={styles.privacyPolicyCheckBox}>
              <input
                checked={isPrivacyPolicyChecked}
                onChange={(e) => setIsPrivacyPolicyChecked(e.target.checked)}
                type="checkbox"
                style={{ accentColor: formColorTheme }}
              />
              <p>
                I agree to {businessName}'s{" "}
                <a
                  href={privacyPolicyLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </p>
            </div>
            {captchaError && (
              <p className={styles.captchaError}>
                Please verify that you are not a robot.
              </p>
            )}
            <ReCAPTCHA
              className={styles.captcha}
              sitekey={captchaKey!}
              onChange={onCaptchaChange}
            />
          </div>
        )}
        <p className={styles.footerText}>
          <a
            href={`https://${process.env.REACT_APP_DOMAIN}${ROUTES.PRIVACY_POLICY}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>{" "}
          |{" "}
          <a
            href={`https://${process.env.REACT_APP_DOMAIN}${ROUTES.TERMS_OF_USE}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms & Conditions
          </a>
          <br />
          ©Copyright 2025 All rights reserved.{" "}
          {!isHidePoweredLabel ? (
            <span>
              Powered By{" "}
              <a
                href={`https://${process.env.REACT_APP_DOMAIN}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                LeadQuiz
              </a>
            </span>
          ) : (
            ""
          )}
        </p>
      </div>
    </div>
  );
};

export default FormViewFooter;
