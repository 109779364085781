import React, { useEffect, useState } from "react";
import styles from "./formTemplatesModal.module.scss";
import Modal from "src/_elements/modal/modal";
import { getFormTemplatesApi } from "src/_utils/api/api";
import { Link } from "react-router-dom";
import ROUTES from "src/_utils/routes/routes";
import FormTemplateItem from "./formTemplateItem/formTemplateItem";
import { useDispatch } from "react-redux";
import { setSelectedFormTemplateIdAC } from "src/store/actions";
import Pagination from "src/pages/leads/pagination/pagination";
import TEMPLATES_CATEGORIES from "src/_utils/templatesCategories";

const FormTemplatesModal: React.FC<any> = ({ isShow, setIsShow }: any) => {
  const dispatch = useDispatch();

  const pageSize = 4;

  const [homeServiceFormTemplates, setHomeServiceFormTemplates] =
    useState<any>(null);
  const [homeServiceCurrentPage, setHomeServiceCurrentPage] = useState(1);
  const [homeServiceTotalPages, setHomeServiceTotalPages] = useState(1);

  const [financialFormTemplates, setFinancialFormTemplates] =
    useState<any>(null);
  const [financialCurrentPage, setFinancialCurrentPage] = useState(1);
  const [financialTotalPages, setFinancialTotalPages] = useState(1);

  const [insuranceFormTemplates, setInsuranceFormTemplates] =
    useState<any>(null);
  const [insuranceCurrentPage, setInsuranceCurrentPage] = useState(1);
  const [insuranceTotalPages, setInsuranceTotalPages] = useState(1);

  const [otherFormTemplates, setOtherFormTemplates] = useState<any>(null);
  const [otherCurrentPage, setOtherCurrentPage] = useState(1);
  const [otherTotalPages, setOtherTotalPages] = useState(1);

  useEffect(() => {
    const payload = {
      pageSize: pageSize,
      page: homeServiceCurrentPage,
      category: TEMPLATES_CATEGORIES.HOME_SERVICE,
    };

    getFormTemplatesApi(payload).then((res) => {
      setHomeServiceFormTemplates(res.data.data.formTemplates);
      setHomeServiceTotalPages(Math.ceil(res.data.data.total / pageSize));
    });
  }, [homeServiceCurrentPage]);

  useEffect(() => {
    const payload = {
      pageSize: pageSize,
      page: financialCurrentPage,
      category: TEMPLATES_CATEGORIES.FINANCIAL,
    };

    getFormTemplatesApi(payload).then((res) => {
      setFinancialFormTemplates(res.data.data.formTemplates);
      setFinancialTotalPages(Math.ceil(res.data.data.total / pageSize));
    });
  }, [financialCurrentPage]);

  useEffect(() => {
    const payload = {
      pageSize: pageSize,
      page: insuranceCurrentPage,
      category: TEMPLATES_CATEGORIES.INSURANCE,
    };

    getFormTemplatesApi(payload).then((res) => {
      setInsuranceFormTemplates(res.data.data.formTemplates);
      setInsuranceTotalPages(Math.ceil(res.data.data.total / pageSize));
    });
  }, [insuranceCurrentPage]);

  useEffect(() => {
    const payload = {
      pageSize: pageSize,
      page: otherCurrentPage,
      category: TEMPLATES_CATEGORIES.OTHER,
    };

    getFormTemplatesApi(payload).then((res) => {
      setOtherFormTemplates(res.data.data.formTemplates);
      setOtherTotalPages(Math.ceil(res.data.data.total / pageSize));
    });
  }, [otherCurrentPage]);

  const handleHomeServicePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= homeServiceTotalPages) {
      setHomeServiceCurrentPage(newPage);
    }
  };

  const handleFinancialPageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= financialTotalPages) {
      setFinancialCurrentPage(newPage);
    }
  };

  const handleInsurancePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= financialTotalPages) {
      setInsuranceCurrentPage(newPage);
    }
  };

  const handleOtherPageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= financialTotalPages) {
      setOtherCurrentPage(newPage);
    }
  };

  const homeServiceFormTemplatesList = homeServiceFormTemplates?.map(
    (res: any, index: number) => (
      <Link
        key={index}
        to={`${ROUTES.FORM_BUILDER}/?template-id=${res.id}`}
        onClick={() => dispatch(setSelectedFormTemplateIdAC(res.id))}
      >
        <FormTemplateItem title={res.name} img={res.formsData[0].logo} />
      </Link>
    )
  );

  const financialFormTemplatesList = financialFormTemplates?.map(
    (res: any, index: number) => (
      <Link
        key={index}
        to={`${ROUTES.FORM_BUILDER}/?template-id=${res.id}`}
        onClick={() => dispatch(setSelectedFormTemplateIdAC(res.id))}
      >
        <FormTemplateItem title={res.name} img={res.formsData[0].logo} />
      </Link>
    )
  );

  const insuranceFormTemplatesList = insuranceFormTemplates?.map(
    (res: any, index: number) => (
      <Link
        key={index}
        to={`${ROUTES.FORM_BUILDER}/?template-id=${res.id}`}
        onClick={() => dispatch(setSelectedFormTemplateIdAC(res.id))}
      >
        <FormTemplateItem title={res.name} img={res.formsData[0].logo} />
      </Link>
    )
  );

  const otherFormTemplatesList = otherFormTemplates?.map(
    (res: any, index: number) => (
      <Link
        key={index}
        to={`${ROUTES.FORM_BUILDER}/?template-id=${res.id}`}
        onClick={() => dispatch(setSelectedFormTemplateIdAC(res.id))}
      >
        <FormTemplateItem title={res.name} img={res.formsData[0].logo} />
      </Link>
    )
  );

  return (
    <Modal
      className={styles.wrapper}
      isShow={isShow}
      onClose={() => setIsShow(false)}
    >
      <div className={styles.content}>
        <p className={styles.title}>Create your form:</p>
        <Link to={ROUTES.FORM_BUILDER} className={styles.btn}>
          Create form
        </Link>
        <p>Or use a ready-made template:</p>

        <div className={styles.templatesWrapper}>
          <p className={styles.templatesCategory}>Home Service</p>
          <div className={styles.formTemplatesListWrapper}>
            {homeServiceFormTemplatesList}
          </div>
          <div className={styles.paginationWrapper}>
            <Pagination
              handlePageChange={handleHomeServicePageChange}
              currentPage={homeServiceCurrentPage}
              totalPages={homeServiceTotalPages}
            />
          </div>
        </div>

        <div className={styles.templatesWrapper}>
          <p className={styles.templatesCategory}>Financial</p>
          <div className={styles.formTemplatesListWrapper}>
            {financialFormTemplatesList}
          </div>
          <div className={styles.paginationWrapper}>
            <Pagination
              handlePageChange={handleFinancialPageChange}
              currentPage={financialCurrentPage}
              totalPages={financialTotalPages}
            />
          </div>
        </div>

        <div className={styles.templatesWrapper}>
          <p className={styles.templatesCategory}>Insurance</p>
          <div className={styles.formTemplatesListWrapper}>
            {insuranceFormTemplatesList}
          </div>
          <div className={styles.paginationWrapper}>
            <Pagination
              handlePageChange={handleInsurancePageChange}
              currentPage={insuranceCurrentPage}
              totalPages={insuranceTotalPages}
            />
          </div>
        </div>

        <div className={styles.templatesWrapper}>
          <p className={styles.templatesCategory}>Other</p>
          <div className={styles.formTemplatesListWrapper}>
            {otherFormTemplatesList}
          </div>
          <div className={styles.paginationWrapper}>
            <Pagination
              handlePageChange={handleOtherPageChange}
              currentPage={otherCurrentPage}
              totalPages={otherTotalPages}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FormTemplatesModal;
