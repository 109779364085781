import Input from "src/_elements/input/input";
import styles from "./googleAdsModal.module.scss";
import { useEffect, useState } from "react";
import Modal from "src/_elements/modal/modal";
import { patchGoogleAdsIdApi, postGoogleAdsIdApi } from "src/_utils/api/api";
import TextArea from "src/_elements/textArea/textArea";

const GoogleAdsModal: React.FC<any> = ({
  isShow,
  handleClose,
  item,
  setIsLoading,
  isEdit,
}) => {
  const [displayName, setDisplayName] = useState("");
  const [googleAdSnippet, setGoogleAdSnippet] = useState("");
  // const [conversionId, setConversionId] = useState("");
  // const [conversionLabel, setConversionLabel] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (isEdit) {
      setDisplayName(item.displayName);
      // setConversionId(item.conversionId);
      // setConversionLabel(item.conversionLabel);
    }
  }, [isEdit, item]);

  const handleSave = () => {
    const idRegex = /AW-([\d]+)/;
    const labelRegex = /AW-[\d]+\/([\w]+)/;

    const conversionIdMatch = googleAdSnippet.match(idRegex);
    const conversionId = conversionIdMatch ? "AW-" + conversionIdMatch[1] : "";

    const conversionLabelMatch = googleAdSnippet.match(labelRegex);
    const conversionLabel = conversionLabelMatch ? conversionLabelMatch[1] : "";

    if (displayName === "" || conversionId === "" || conversionLabel === "") {
      setErrorMessage("Incorrect google ad snippet");
    } else {
      if (!isEdit) {
        const payload = {
          displayName: displayName,
          conversionId: conversionId,
          conversionLabel: conversionLabel,
        };

        postGoogleAdsIdApi(payload).then(() => {
          setIsLoading(true);
          handleClose();
        });
      } else {
        const payload = {
          displayName: displayName,
          conversionId: conversionId,
          conversionLabel: conversionLabel,
        };

        patchGoogleAdsIdApi(payload, item.id).then(() => {
          setIsLoading(true);
          handleClose();
        });
      }
    }
  };

  return (
    <Modal className={styles.wrapper} isShow={isShow} onClose={handleClose}>
      <div className={styles.content}>
        <p className={styles.title}>
          {isEdit ? "Edit Google Ads" : "Add new Google Ads"}
        </p>
        <div className={styles.inputs}>
          <Input
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            placeholder="Display name"
            label="Display name"
          />
          <TextArea
            value={googleAdSnippet}
            onChange={(e) => setGoogleAdSnippet(e.target.value)}
            placeholder="Enter new Google Ad snippet"
            label="Enter new Google Ad snippet"
          />
        </div>
        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
        <div className={styles.btnWrap}>
          <button onClick={handleSave}>Save</button>
        </div>
      </div>
    </Modal>
  );
};

export default GoogleAdsModal;
