import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAvailablePlansListApi } from "src/_utils/api/api";
import { IPlanData } from "src/pages/interfaces/interfaces";
import PaymentPlan from "./paymentPlan/paymentPlan";
import styles from "./payments.module.scss";
import stylesTrial from "./paymentsTrial.module.scss";
import PaymentType from "./paymentType/paymentType";
import TrialPricingSlider from "./trialPricingSlider/trialPricingSlider";
import PRICING_TYPES from "src/_utils/pricingTypes";

const Payments: React.FC<any> = ({ isTrial, isTrialModal }: any) => {
  const isUserPlanActive = useSelector((state: any) => state.isUserPlanActive);
  const currentUserPlan = useSelector((state: any) => state.currentUserPlan);

  const [selectedPaymentType, setselectedPaymentType] = useState(
    PRICING_TYPES.MONTH_PAYMENT_TYPE
  );
  const [plansList, setPlansList] = useState<IPlanData[] | null>(null);
  const [currentPlan, setCurrentPlan] = useState<any>(null);
  const [isCurrentPlanAvailable, setIsCurrentPlanAvailable] = useState(false);
  const [showAllPlans, setShowAllPlans] = useState(false);

  useEffect(() => {
    getAvailablePlansListApi()
      .then((res) => {
        if (!currentUserPlan?.needUpdatePlan && isUserPlanActive) {
          const foundPlans = res.data.data.filter(
            (plan: IPlanData) => plan.id !== currentUserPlan?.planId
          );
          setPlansList(foundPlans);
        } else {
          setPlansList(res.data.data);
        }

        const foundPlan = res.data.data.find(
          (plan: IPlanData) => plan.id === currentUserPlan?.planId
        );
        setCurrentPlan(foundPlan);
      })
      .catch((err) => console.log(err));
  }, [currentUserPlan, isUserPlanActive]);

  useEffect(() => {
    if (currentPlan?.name && isUserPlanActive) {
      setIsCurrentPlanAvailable(true);
    } else {
      setIsCurrentPlanAvailable(false);
    }
  }, [currentUserPlan, currentPlan]);

  const enterpriseList = [
    "Custom Questions",
    "Customized Domain Solutions",
    "Customized Submission Limits",
    "Designated Account Manager",
  ];

  const visiblePlans = showAllPlans ? plansList : plansList?.slice(0, 3);

  const paymentPlans = visiblePlans?.map((el: IPlanData) => (
    <PaymentPlan
      price={
        selectedPaymentType === PRICING_TYPES.YEAR_PAYMENT_TYPE
          ? el.stripePrices.pricePerYear.unit_amount
          : selectedPaymentType === PRICING_TYPES.MONTH_PAYMENT_TYPE &&
            el.stripePrices.pricePerMonth.unit_amount
      }
      pricePerForm={
        selectedPaymentType === PRICING_TYPES.YEAR_PAYMENT_TYPE
          ? el.formPrice?.displayYearlyPrice
          : selectedPaymentType === PRICING_TYPES.MONTH_PAYMENT_TYPE &&
            el.formPrice?.displayMonthlyPrice
      }
      title={el.name}
      maxDomains={el.maxDomains}
      maxSubmissionsPerMonth={el.maxSubmissionsPerMonth}
      key={el.id}
      id={el.id}
      priceId={
        selectedPaymentType === PRICING_TYPES.YEAR_PAYMENT_TYPE
          ? el.stripePrices.pricePerYear.id
          : selectedPaymentType === PRICING_TYPES.MONTH_PAYMENT_TYPE &&
            el.stripePrices.pricePerMonth.id
      }
      isNeedToContactSales={el.needToContactSales}
      isCurrentPlanAvailable={isCurrentPlanAvailable}
      isYearly={selectedPaymentType === PRICING_TYPES.YEAR_PAYMENT_TYPE}
      isPersonalPlan={el.userId !== undefined}
      isTrial={isTrial}
    />
  ));

  const enterprisePlan = (
    <PaymentPlan title="Enterprise" list={enterpriseList} isTrial={isTrial} />
  );

  return (
    <div>
      <div className={styles.wrapper}>
        <PaymentType
          selectedPaymentType={selectedPaymentType}
          setselectedPaymentType={setselectedPaymentType}
          isTrial={isTrial}
        />
        <div
          className={
            isTrialModal
              ? styles.paymentPlansWrapper
              : isTrial
              ? stylesTrial.paymentPlansWrapper
              : styles.paymentPlansWrapper
          }
        >
          {paymentPlans}
          {enterprisePlan}
        </div>
        {plansList && plansList.length > 3 && (
          <button
            className={isTrial ? stylesTrial.showMoreBtn : styles.showMoreBtn}
            onClick={() => setShowAllPlans(!showAllPlans)}
          >
            {showAllPlans ? "Show less" : "Show more"}
          </button>
        )}
      </div>
      {isTrial && !isTrialModal && (
        <TrialPricingSlider
          paymentPlans={paymentPlans}
          enterprisePlan={enterprisePlan}
        />
      )}
    </div>
  );
};

export default Payments;
