import Header from "src/pages/homePage/header/header";
import styles from "./../article.module.scss";
import Footer from "src/pages/homePage/footer/footer";
import { useEffect } from "react";
import dataSet1Img from "src/assets/support/facebookPixelSetup/dataSet1.jpg";
import dataSet2Img from "src/assets/support/facebookPixelSetup/dataSet2.jpg";
import dataSet3Img from "src/assets/support/facebookPixelSetup/dataSet3.jpg";
import customConversionSetup1Img from "src/assets/support/facebookPixelSetup/customConversionSetup1.jpg";
import customConversionSetup2Img from "src/assets/support/facebookPixelSetup/customConversionSetup2.jpg";
import customConversionSetup3Img from "src/assets/support/facebookPixelSetup/customConversionSetup3.jpg";
import adsManagerScreenImg from "src/assets/support/facebookPixelSetup/adsManagerScreen.jpg";
import installBaseCode from "src/assets/support/facebookPixelSetup/installBaseCode.jpg";
import { Helmet } from "react-helmet";

const HowToTrackFacebookAd = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.outerWrapper}>
      <Helmet>
        <title>LeadQuiz | Support - How to track facebook pixel</title>
        <meta
          name="description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <link
          rel="canonical"
          href="https://leadquiz.com/support/how-to-track-facebook-ad"
        />
        <meta
          property="og:title"
          content="LeadQuiz | Support - How to track facebook pixel"
        />
        <meta
          property="og:description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://leadquiz.com/support/how-to-track-facebook-ad"
        />
        <meta property="og:image" content="src/assets/homePage/devices.png" />
        <meta
          name="keywords"
          content="LeadQuiz, form builder, create form, generate form, Lead Quiz"
        />
      </Helmet>
      <Header />
      <div className={styles.wrapper}>
        <h1>How to Track Facebook Ad Conversions (FB Pixel) with LeadQuiz</h1>
        <p>
          To track conversions from your Facebook ads with LeadQuiz, you’ll need
          to install the Facebook Pixel on your form and set up a custom
          conversion for tracking leads. Follow the step-by-step instructions
          below to properly set up tracking, so you can analyze how well your
          ads are converting.
        </p>

        <b className={styles.step}>
          Step 1: Install the Facebook Pixel on Your LeadQuiz Form
        </b>
        <ul className={styles.numericUl}>
          <li>
            <b>Log in to your LeadQuiz account.</b>
          </li>
          <li>Open the form you want to track.</li>
          <li>
            Navigate to the <b>Settings</b> tab in the form builder.
          </li>
          <li>
            Locate the section for <b>Tracking Pixels</b>.
          </li>
          <li>
            <b>Enter your Facebook Pixel ID</b> in the designated field.
          </li>
          <ul className={styles.subUl}>
            <li>
              If you don’t have a Pixel ID, you can create one in{" "}
              <b>Facebook Meta Business Suite</b>.
            </li>
          </ul>
        </ul>
        <p>
          The Facebook Pixel will now track events on this LeadQuiz form,
          including page views and form submissions.
        </p>

        <b className={styles.step}>
          Step 2: Create a Dataset in Meta Business Suite
        </b>
        <ul className={styles.numericUl}>
          <li>
            Log in to your <b>Meta Business Suite</b>.
          </li>
          <li>
            Go to <b>Data Sources</b> on the left-hand menu and click on{" "}
            <b>Datasets</b>.
          </li>
          <li>
            Click the <b>Add</b> button and follow the prompts to create a new
            dataset for LeadQuiz.
          </li>
        </ul>
        <p>
          Here is an example of what it looks like when your dataset is created:
        </p>
        <br />
        <img src={dataSet1Img} alt="data set 1" />
        <img src={dataSet2Img} alt="data set 2" />
        <img src={dataSet3Img} alt="data set 3" />

        <b className={styles.step}>
          Step 3: Set Up a Custom Conversion for Tracking Leads
        </b>
        <ul className={styles.numericUl}>
          <li>
            In <b>Meta Business Suite</b>, navigate to <b>Events Manager</b> and
            click on <b>Create Custom Conversion</b>.
          </li>
          <li>
            Name the conversion <b>Lead</b> and select your{" "}
            <b>LeadQuiz dataset</b> as the data source.
          </li>
          <li>
            In the <b>Rules</b> section, set up a rule to track visitors to your
            thank-you page by selecting:
          </li>
          <ul className={styles.subUl}>
            <li>
              <b>URL contains</b>:{" "}
              <span>yourbusiness.leadquiz.com/thank-you</span>
            </li>
          </ul>
          <li>Save the custom conversion.</li>
        </ul>
        <p>Here is an example of the Custom Conversion setup screen:</p>
        <br />
        <img src={customConversionSetup1Img} alt="custom conversion setup 1" />
        <img src={customConversionSetup2Img} alt="custom conversion setup 2" />
        <img src={customConversionSetup3Img} alt="custom conversion setup 3" />
        <p>
          This step will allow you to track conversions specifically when a lead
          submits your form and lands on the thank-you page.
        </p>

        <b className={styles.step}>
          Step 4: Copy and Paste the Pixel ID into LeadQuiz
        </b>
        <ul className={styles.numericUl}>
          <li>
            In <b>Connect Data</b>, find the <b>Pixel ID</b> in the
            Install base code (see screenshot below).
            <img src={installBaseCode} alt="Install base code" />
          </li>
          <li>
            Copy the <b>Pixel ID</b>.
          </li>
          <li>
            Return to your LeadQuiz form settings, and paste the{" "}
            <b>Pixel ID</b> into the <b>Facebook Pixel/Data Sources</b> field.
          </li>
        </ul>
        <p>
          Now, Facebook will track every lead that completes your form and lands
          on your thank-you page.
        </p>

        <b className={styles.step}>
          Step 5: Monitor Conversions in Facebook Ads Manager
        </b>
        <ul className={styles.numericUl}>
          <li>
            After setting up your Facebook Pixel and Custom Conversion, go to{" "}
            <b>Facebook Ads Manager</b> to track the performance of your
            campaigns.
          </li>
          <li>
            Under your ad reports, you’ll see conversions marked as <b>Lead</b>,
            allowing you to analyze the performance of your ads.
          </li>
        </ul>
        <p>
          Here’s an example of the Ads Manager screen showing conversion data:
        </p>
        <br />
        <img src={adsManagerScreenImg} alt="Ads manager screen" />

        <b className={styles.step}>Conclusion</b>
        <p>
          By following these steps, you’ll be able to track Facebook ad
          conversions and monitor how well your campaigns are performing through
          LeadQuiz. This allows you to make data-driven decisions to optimize
          your ad strategy.
        </p>
        <p>
          If you have any questions or need help with any step, feel free to
          reach out to our support team at support@perstrive.com.
        </p>
      </div>
      <div className={styles.footerWrap}>
        <Footer />
      </div>
    </div>
  );
};

export default HowToTrackFacebookAd;
