import styles from "./login.module.scss";
import { Helmet } from "react-helmet";
import LoginComponent from "./loginComponent/loginComponent";

const LogIn = () => {
  return (
    <div className={styles.outerWrapper}>
      <Helmet>
        <title>LeadQuiz | Login</title>
        <meta
          name="description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <link rel="canonical" href="https://leadquiz.com/login" />
        <meta property="og:title" content="LeadQuiz | Login" />
        <meta
          property="og:description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://leadquiz.com/login" />
        <meta property="og:image" content="src/assets/homePage/devices.png" />
        <meta
          name="keywords"
          content="LeadQuiz, form builder, create form, generate form"
        />
        <meta
          name="keywords"
          content="LeadQuiz, form builder, create form, generate form, Lead Quiz"
        />
      </Helmet>
      <LoginComponent />
    </div>
  );
};

export default LogIn;
