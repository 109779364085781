import React, { useEffect, useState } from "react";
import styles from "./chooseHowShallWeContactYou.module.scss";
import FormInput from "src/_elements/formInput/formInput";
import AddElementButton from "src/_elements/addElementButton/addElementButton";
import TextArea from "src/_elements/textArea/textArea";
import { useDispatch, useSelector } from "react-redux";
import { setHSWCYOtherInfoAC, setHSWCYOTitleAC } from "src/store/actions";
import deleteIcon from "./../../../../assets/trash.svg";
import HSWCYpopup from "./HSWCYpopup/HSWCYpopup";
import DatePickerComponent from "src/_elements/datePickerComponent/datePickerComponent";
import TimePicker from "src/_elements/timePicker/timePicker";

const ChooseHowShallWeContactYou: React.FC<any> = ({ otherInfo }: any) => {
  const dispatch = useDispatch();

  const HSWCYOTitle = useSelector((state: any) => state.HSWCYOTitle);

  const [elements, setElements] = useState<any>([]);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    dispatch(setHSWCYOtherInfoAC(elements));
  }, [elements]);

  const handleAddElement = () => {
    setShowPopup(true);
  };

  useEffect(() => {
    if (otherInfo && otherInfo[0]) {
      otherInfo.map((el: any) => {
        let newElement: any;
        switch (el.type) {
          case "date":
            newElement = {
              type: "date",
              label: "Date",
              component: <DatePickerComponent value="01/01/2000" />,
            };
            break;
          case "time":
            newElement = {
              type: "time",
              label: "Time",
              component: <TimePicker />,
            };
            break;
          case "text":
            newElement = {
              type: "text",
              label: "Input",
              component: <FormInput placeholder="Text input" value="" />,
            };
            break;
          case "textarea":
            newElement = {
              type: "textarea",
              label: "Text area",
              component: <TextArea placeholder="Text area" value="" />,
            };
            break;
          case "city":
            newElement = {
              type: "city",
              label: "City",
              component: <FormInput placeholder="City" value="" />,
            };
            break;
          case "state":
            newElement = {
              type: "state",
              label: "State",
              component: <FormInput placeholder="State" value="" />,
            };
            break;
          case "zipCode":
            newElement = {
              type: "zipCode",
              label: "Zip Code",
              component: <FormInput placeholder="Zip Code" value="" />,
            };
            break;
          case "address":
            newElement = {
              type: "address",
              label: "Address",
              component: <FormInput placeholder="Address" value="" />,
            };
            break;
          default:
            break;
        }
        if (newElement) {
          setElements((prevState: any) => [...prevState, newElement]);
        }
      });
    }

    return () => {
      setElements([]);
    };
  }, [otherInfo]);

  const handleElementSelect = (selectedElement: string) => {
    if (selectedElement) {
      let newElement;
      switch (selectedElement) {
        case "date":
          newElement = {
            type: "date",
            label: "Date",
            component: <DatePickerComponent value="01/01/2000" />,
          };
          break;
        case "time":
          newElement = {
            type: "time",
            label: "Time",
            component: <TimePicker />,
          };
          break;
        case "text":
          newElement = {
            type: "text",
            label: "Input",
            component: <FormInput placeholder="Text input" value="" />,
          };
          break;
        case "textarea":
          newElement = {
            type: "textarea",
            label: "Text area",
            component: <TextArea placeholder="Text area" value="" />,
          };
          break;
        case "city":
          newElement = {
            type: "city",
            label: "City",
            component: <FormInput placeholder="City" value="" />,
          };
          break;
        case "state":
          newElement = {
            type: "state",
            label: "State",
            component: <FormInput placeholder="State" value="" />,
          };
          break;
        case "zipCode":
          newElement = {
            type: "zipCode",
            label: "Zip Code",
            component: <FormInput placeholder="Zip Code" value="" />,
          };
          break;
        case "address":
          newElement = {
            type: "address",
            label: "Address",
            component: <FormInput placeholder="Address" value="" />,
          };
          break;
        default:
          break;
      }
      if (newElement) {
        setElements([...elements, newElement]);
        setShowPopup(false);
      }
    }
  };

  const handleRemoveElement = (index: number) => {
    setElements(elements.filter((_: any, i: any) => i !== index));
  };

  const handleLabelChange = (index: number, newLabel: string) => {
    const updatedElements = [...elements];
    updatedElements[index].label = newLabel;
    setElements(updatedElements);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.wrapper}>
        <h2>Enter Confirmation Page Text</h2>
        <div className={styles.editWrapper}>
          <div>
            <FormInput
              value={HSWCYOTitle}
              onChange={(e) => dispatch(setHSWCYOTitleAC(e.target.value))}
            />
          </div>
          <div className={styles.notEditable}>
            <div className={styles.nameSection}>
              <div>
                <p>*First name:</p>
                <FormInput value="First name" />
              </div>
              <div>
                <p>*Last name:</p>
                <FormInput value="Last name" />
              </div>
            </div>
            <div>
              <p>*Phone number:</p>
              <FormInput value="+1" />
            </div>
            <div>
              <p>*Email:</p>
              <FormInput value="Email" />
            </div>
          </div>

          <div className={styles.otherInfo}>
            {elements.map((element: any, index: number) => (
              <div key={index} className={styles.elementWrapper}>
                <input
                  type="text"
                  value={element.label}
                  onChange={(e) => handleLabelChange(index, e.target.value)}
                  className={styles.labelInput}
                />
                <div className={styles.elementAndDeleteBtn}>
                  {element.component}
                  <button
                    onClick={() => handleRemoveElement(index)}
                    className={styles.deleteButton}
                  >
                    <img src={deleteIcon} alt="delete" />
                  </button>
                </div>
              </div>
            ))}
            <AddElementButton onClick={handleAddElement}>Add</AddElementButton>
            {showPopup && (
              <HSWCYpopup
                handleClosePopup={handleClosePopup}
                handleElementSelect={handleElementSelect}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseHowShallWeContactYou;
